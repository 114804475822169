import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { pageTransitionOff } from '../../redux/actions';

const mapStateToProps = (state) => {
    return {
        routeTo: state.AppReducer.routeTo,
        pageTransition: state.AppReducer.pageTransition,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        pageTransitionOff: () => dispatch(pageTransitionOff()),
    }
}

const PageTransition = (props) => {
    const navigate = useNavigate();

    const transitionComponent = useRef(null);
    const logo = useRef(null);

    const startTransition = (route) => {
        gsap.to(transitionComponent.current.children[0], {
            duration: .5,
            scaleX: 1,
            transformOrigin: "right",
            ease: "power1.inOut"
        });
        gsap.to(transitionComponent.current.children[0], {
            duration: .5,
            scaleX: 0,
            transformOrigin: "left",
            ease: "power1.inOut",
            delay: 1
        });

        gsap.to(transitionComponent.current.children[1], {
            duration: .7,
            scaleX: 1,
            transformOrigin: "right",
            ease: "power1.inOut",
            onComplete: () => {
                navigate(props.routeTo);
            }
        });

        gsap.to(logo.current, .5, {
            delay: .3,
            opacity: 1,
        });

        gsap.to(logo.current, .6, {
            delay: .8,
            opacity: 0,
        });

        gsap.to(transitionComponent.current.children[1], {
            duration: .7,
            scaleX: 0,
            transformOrigin: "left",
            ease: "power1.inOut",
            delay: .8,
            onComplete: () => {
                props.pageTransitionOff();
            }
        });
    }

    const setTransitionDefault = () => {
        gsap.set([transitionComponent.current.children[0], transitionComponent.current.children[1]], {
            transformOrigin: "right",
            scaleX: 0
        })
    }

    useEffect(() => {
        if (props.pageTransition) {
            startTransition();
        } else {
            setTransitionDefault();
        }
    }, [props.pageTransition])

    return (
        <div ref={transitionComponent} className="component-transition">
            <div className="component-transition__overlay"></div>
            <div className="component-transition__overlay component-transition__overlay--two"></div>
            <div ref={logo} className="component-transition__logo">
                <svg width="120" height="67" viewBox="0 0 120 67" xmlns="http://www.w3.org/2000/svg">
                    <path d="M59.612.003a33.157 33.157 0 0 1 15 3.379.333.333 0 0 1 .153.45l-.145.274a11.767 11.767 0 0 1-11.852 6.057 23.326 23.326 0 0 0-25.25 16.935h.001l-.042.15-.105.396-.03.12-.072.315-.07.315-.02.066-.031.15-.003.04-.038.193.038-.182a26.695 26.695 0 0 0-.069.339l-.022.11-.08.46-.062.4a23.71 23.71 0 0 0-.244 3.356 23.186 23.186 0 0 0 2.39 10.227.333.333 0 0 1-.3.483c-4.082.013-8.848-2.436-11.414-7.768-.23-.486-.4-.996-.507-1.52l-.129.07c-6.631 3.726-10.347 7.325-9.63 9.998.716 2.672 5.721 3.932 13.329 3.839l1.258-.033a96.368 96.368 0 0 0 10.022-.854h.033l.004.005a168.734 168.734 0 0 0 20.993-4.283 167.74 167.74 0 0 0 20.36-6.805 95.711 95.711 0 0 0 10.204-4.862c6.632-3.726 10.34-7.351 9.63-9.997-.709-2.646-5.63-3.912-13.122-3.842a.333.333 0 0 1-.3-.177 32.948 32.948 0 0 0-1.593-2.706.267.267 0 0 1 .187-.41c17.975-2.545 30.647-1.819 31.827 2.58 1.186 4.435-9.597 11.476-26.655 18.294a218.635 218.635 0 0 1-10.77 3.999 292.389 292.389 0 0 1-19.238 5.852 294.532 294.532 0 0 1-19.58 4.552l.028-.006c8.955 8.825 23.304 8.933 32.392.242a12.063 12.063 0 0 1 13.409-2.123.333.333 0 0 1 .157.457 33.324 33.324 0 0 1-57.325 3.364C14.129 54.52 1.286 53.816.09 49.382c-1.196-4.436 9.59-11.477 26.659-18.309C27.905 13.912 42.224.2 59.612.003z" fill="#FFF"/>
                </svg>
                <h2 className="coming-soon__logo-subtitle">Cosmic Motive</h2>
            </div>

        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTransition);