function ComingSoon() {
    return (
        <section className="coming-soon">
            <div className="coming-soon__container">
                <div className="coming-soon__logo">
                    <svg width="120" height="67" viewBox="0 0 120 67" xmlns="http://www.w3.org/2000/svg">
                        <path d="M59.612.003a33.157 33.157 0 0 1 15 3.379.333.333 0 0 1 .153.45l-.145.274a11.767 11.767 0 0 1-11.852 6.057 23.326 23.326 0 0 0-25.25 16.935h.001l-.042.15-.105.396-.03.12-.072.315-.07.315-.02.066-.031.15-.003.04-.038.193.038-.182a26.695 26.695 0 0 0-.069.339l-.022.11-.08.46-.062.4a23.71 23.71 0 0 0-.244 3.356 23.186 23.186 0 0 0 2.39 10.227.333.333 0 0 1-.3.483c-4.082.013-8.848-2.436-11.414-7.768-.23-.486-.4-.996-.507-1.52l-.129.07c-6.631 3.726-10.347 7.325-9.63 9.998.716 2.672 5.721 3.932 13.329 3.839l1.258-.033a96.368 96.368 0 0 0 10.022-.854h.033l.004.005a168.734 168.734 0 0 0 20.993-4.283 167.74 167.74 0 0 0 20.36-6.805 95.711 95.711 0 0 0 10.204-4.862c6.632-3.726 10.34-7.351 9.63-9.997-.709-2.646-5.63-3.912-13.122-3.842a.333.333 0 0 1-.3-.177 32.948 32.948 0 0 0-1.593-2.706.267.267 0 0 1 .187-.41c17.975-2.545 30.647-1.819 31.827 2.58 1.186 4.435-9.597 11.476-26.655 18.294a218.635 218.635 0 0 1-10.77 3.999 292.389 292.389 0 0 1-19.238 5.852 294.532 294.532 0 0 1-19.58 4.552l.028-.006c8.955 8.825 23.304 8.933 32.392.242a12.063 12.063 0 0 1 13.409-2.123.333.333 0 0 1 .157.457 33.324 33.324 0 0 1-57.325 3.364C14.129 54.52 1.286 53.816.09 49.382c-1.196-4.436 9.59-11.477 26.659-18.309C27.905 13.912 42.224.2 59.612.003z" fill="#FFF"/>
                    </svg>
                </div>
                <h2 className="coming-soon__logo-subtitle">Cosmic Motive</h2>
                <h1 className="coming-soon__title">Website Coming Soon.</h1>

                <div className="btn__container--coming-soon">
                    <span className="btn btn--move">CONNECT WITH US
                        <div className="btn__line"></div>
                    </span>
                </div>

                <div className="coming-soon__social">
                    <a href="https://dribbble.com/CosmicMotive" target="_blank" rel="noreferrer noopener nofollow" className="btn--icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                            <path d="M27.859 8.856a14.533 14.533 0 0 0-7.714-7.715A14.51 14.51 0 0 0 14.501 0a14.551 14.551 0 0 0-5.646 1.14 14.51 14.51 0 0 0-4.61 3.109A14.583 14.583 0 0 0 1.14 8.856a14.444 14.444 0 0 0-.847 2.726 14.677 14.677 0 0 0 0 5.839c.19.932.476 1.849.847 2.723.362.86.814 1.692 1.337 2.466a14.75 14.75 0 0 0 1.769 2.144 14.558 14.558 0 0 0 4.61 3.105c.875.372 1.792.655 2.724.844.955.198 1.937.297 2.92.297.983 0 1.965-.099 2.92-.297.931-.189 1.848-.472 2.725-.843.86-.364 1.688-.814 2.463-1.337.768-.52 1.49-1.114 2.146-1.769a14.699 14.699 0 0 0 1.769-2.144c.523-.774.975-1.606 1.336-2.466.37-.874.657-1.791.848-2.723a14.655 14.655 0 0 0 0-5.839c-.19-.931-.478-1.85-.848-2.726zM14.5 2.116c3.134 0 5.997 1.171 8.178 3.093-.031.046-1.794 2.73-6.47 4.482a62.95 62.95 0 0 0-4.628-7.224c.937-.227 1.914-.35 2.92-.35zm-2.967.36c0 .003-.002.005-.002.005l-.038.006c.014-.003.026-.005.04-.01zm-2.318.826a74.16 74.16 0 0 1 4.6 7.134c-5.947 1.58-11.108 1.518-11.434 1.512a12.388 12.388 0 0 1 6.834-8.646zM5.29 22.775A12.336 12.336 0 0 1 2.116 14.5c0-.131.006-.26.01-.39.22.005 6.338.143 12.714-1.765.355.694.694 1.402 1.004 2.105a8.425 8.425 0 0 0-.488.15c-6.682 2.158-10.072 8.17-10.072 8.17l.005.006zm9.212 4.109a12.331 12.331 0 0 1-7.731-2.712c-.045-.035-.097-.075-.16-.127.063.052.115.092.16.127l.118.09s2.374-5.176 9.719-7.736c.027-.01.056-.017.083-.025 1.742 4.527 2.461 8.318 2.645 9.4-1.485.632-3.12.983-4.834.983zm6.92-2.114c-.127-.747-.787-4.382-2.412-8.844 3.999-.64 7.462.457 7.718.54a12.389 12.389 0 0 1-5.306 8.304zm-3.166-10.8c-.088-.21-.175-.424-.267-.637a36.94 36.94 0 0 0-.824-1.798c4.89-1.996 6.876-4.865 6.9-4.9a12.34 12.34 0 0 1 2.817 7.742c-.18-.04-4.37-.942-8.626-.407z" fill="#FFF"/>
                        </svg>
                    </a>

                    <a href="https://www.behance.net/CosmicMotive" target="_blank" rel="noreferrer noopener nofollow" className="btn--icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="18" viewBox="0 0 29 18">
                            <path d="M11.93 8.136c.602-.302 1.058-.636 1.368-1 .554-.651.829-1.515.829-2.585 0-1.041-.275-1.934-.822-2.68C12.393.654 10.846.03 8.66 0H0v17.557h8.075c.91 0 1.753-.077 2.532-.235.78-.16 1.454-.453 2.024-.882a4.884 4.884 0 0 0 1.269-1.382c.534-.825.8-1.759.8-2.8 0-1.008-.234-1.868-.698-2.572-.468-.707-1.158-1.223-2.071-1.55zM3.574 3.049h3.9c.858 0 1.564.091 2.12.273.641.265.962.804.962 1.624 0 .735-.244 1.25-.728 1.54-.488.291-1.12.436-1.896.436H3.573V3.05zm18.844 1.18c1.154 0 2.193.203 3.116.608.927.405 1.689 1.046 2.289 1.922.547.772.898 1.667 1.058 2.685.095.594.132 1.457.117 2.577H19.34c.058 1.302.515 2.215 1.388 2.738.528.326 1.164.49 1.91.49.787 0 1.43-.199 1.923-.595.27-.215.51-.513.714-.894h3.538c-.094.769-.523 1.552-1.283 2.348C26.343 17.368 24.68 18 22.545 18c-1.765 0-3.322-.53-4.667-1.597-1.352-1.064-2.024-2.798-2.024-5.197 0-2.25.609-3.976 1.825-5.177 1.219-1.198 2.796-1.8 4.738-1.8zM7.985 9.826c.767.008 1.366.107 1.792.298.762.342 1.14.969 1.14 1.884 0 1.08-.391 1.808-1.178 2.192-.431.206-1.038.31-1.816.31h-4.35V9.826h4.412zM26.21.818v2.185h-7.68V.818h7.68zm-6.784 8.963c.127-.845.435-1.515.925-2.01.489-.494 1.18-.742 2.067-.742.819 0 1.503.233 2.059.699.549.469.86 1.15.922 2.053h-5.973z" fill="#FFF"/>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/77155561" target="_blank" rel="noreferrer noopener nofollow" className="btn--icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27">
                            <path d="M6.603 8.659V27H.756V8.659h5.847zm15.976-.177c4.156 0 7.421 2.639 7.421 8.32V27h-6.63v-9.492c0-2.484-1.037-4.18-3.318-4.18-1.745 0-2.715 1.145-3.166 2.248-.17.395-.143.947-.143 1.499V27h-6.569s.085-16.813 0-18.341h6.569v2.878c.388-1.259 2.487-3.055 5.836-3.055zM3.572 0c2.157 0 3.484 1.37 3.526 3.18 0 1.777-1.369 3.184-3.567 3.184h-.042C1.373 6.364 0 4.963 0 3.184 0 1.374 1.413 0 3.572 0z" fill="#FFF"/>
                        </svg>
                    </a>
                </div>
            </div>
        </section>
    )

}

export default ComingSoon;