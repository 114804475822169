
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';

export const PRELOADER_ON = 'PRELOADER_ON';
export const PRELOADER_OFF = 'PRELOADER_OFF';

export const PAGE_TRANSITION_ON = 'PAGE_TRANSITION_ON';
export const PAGE_TRANSITION_OFF = 'PAGE_TRANSITION_OFF';

export const INCREMENT_LOADED_IMAGES = 'INCREMENT_LOADED_IMAGES';

export const MOBILE_MENU_ON = 'MOBILE_MENU_ON';
export const MOBILE_MENU_OFF = 'MOBILE_MENU_OFF';

export const MOBILE_VIEW_ON = 'MOBILE_VIEW_ON';
export const MOBILE_VIEW_OFF = 'MOBILE_VIEW_OFF';

export const pageBlockTypes = {
    image: 'image',
    avaliableOn: 'avaliableOn',
    text: 'text',
    textWithBg: 'textWithBg',
    h2: 'h2',
    parallaxImgBg: 'parallaxImgBg',
    textWith3Cols: 'textWith3Cols'
}

export const ideasPageBlockTypes = {
    text: 'text',
}