const Particles = () => {
  return (
    <svg width="260" height="232" viewBox="0 0 260 232" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_409)">
        <path d="M98.3903 78.7057C100.161 78.6927 101.607 77.2343 101.607 75.4634V36.7505C101.607 35.8781 101.268 35.0577 100.643 34.4327C100.031 33.8207 99.2107 33.4821 98.3513 33.4821H98.3252C44.1168 33.8467 0 78.263 0 132.484C0 186.706 44.1038 231.122 98.3252 231.487H98.3513C99.2237 231.487 100.031 231.148 100.643 230.536C101.268 229.924 101.607 229.091 101.607 228.218V189.506C101.607 187.735 100.161 186.276 98.3903 186.263C69.079 185.938 45.2236 161.822 45.2236 132.484C45.2236 103.147 69.079 79.0443 98.3903 78.7057Z" fill="white" />
        <path d="M257.396 136.625H209.516C208.083 136.625 206.911 135.453 206.911 134.021C206.911 132.589 208.083 131.417 209.516 131.417H257.396C258.828 131.417 260 132.589 260 134.021C260 135.453 258.828 136.625 257.396 136.625Z" fill="white" />
        <path d="M109.901 77.4296C108.469 77.4296 107.297 76.2577 107.297 74.8253V37.024C107.297 35.5916 108.469 34.4197 109.901 34.4197C111.334 34.4197 112.506 35.5916 112.506 37.024V74.8253C112.506 76.2577 111.334 77.4296 109.901 77.4296Z" fill="white" />
        <path d="M109.901 230.823C108.469 230.823 107.297 229.651 107.297 228.218V188.568C107.297 187.136 108.469 185.964 109.901 185.964C111.334 185.964 112.506 187.136 112.506 188.568V228.218C112.506 229.651 111.334 230.823 109.901 230.823Z" fill="white" />
        <path d="M147.052 176.093C146.218 176.093 145.398 175.703 144.903 174.961L141.934 170.611C141.127 169.426 141.426 167.799 142.611 166.991C143.809 166.184 145.424 166.484 146.231 167.669L149.2 172.018C150.008 173.203 149.708 174.83 148.523 175.638C148.067 175.95 147.56 176.093 147.052 176.093Z" fill="white" />
        <path d="M167.027 79.5C166.363 79.5 165.698 79.2396 165.191 78.7318C164.175 77.7161 164.175 76.0624 165.191 75.0467L201.547 38.6907C202.562 37.675 204.216 37.675 205.232 38.6907C206.247 39.7064 206.247 41.3601 205.232 42.3758L168.876 78.7318C168.368 79.2396 167.704 79.5 167.04 79.5H167.027Z" fill="white" />
        <path d="M195.739 101.077C194.71 101.077 193.747 100.478 193.33 99.462C192.783 98.1338 193.408 96.6103 194.736 96.0634L213.474 88.3026C214.802 87.7557 216.326 88.3807 216.873 89.7089C217.42 91.0371 216.795 92.5606 215.467 93.1075L196.729 100.868C196.403 100.999 196.065 101.064 195.739 101.064V101.077Z" fill="white" />
        <path d="M209.216 177.76C208.891 177.76 208.552 177.695 208.227 177.565L177.001 164.635C175.673 164.088 175.048 162.564 175.595 161.236C176.142 159.908 177.665 159.27 178.993 159.83L210.219 172.76C211.547 173.307 212.172 174.83 211.625 176.159C211.208 177.161 210.245 177.773 209.216 177.773V177.76Z" fill="white" />
        <path d="M150.815 37.8443C150.489 37.8443 150.151 37.7792 149.825 37.649C148.497 37.1021 147.872 35.5786 148.419 34.2504L161.024 3.81915C161.571 2.49096 163.094 1.8529 164.422 2.41283C165.751 2.95973 166.376 4.48324 165.829 5.81144L153.224 36.2426C152.807 37.2453 151.844 37.8573 150.815 37.8573V37.8443Z" fill="white" />
        <path d="M210.115 123.435C208.826 123.435 207.706 122.484 207.536 121.169C207.354 119.736 208.357 118.434 209.776 118.252L249.687 112.991C251.106 112.796 252.421 113.812 252.604 115.231C252.786 116.65 251.783 117.966 250.364 118.148L210.453 123.408C210.336 123.422 210.219 123.435 210.115 123.435Z" fill="white" />
        <path d="M123.248 219.403C121.959 219.403 120.839 218.452 120.67 217.137L116.256 183.568C116.073 182.135 117.076 180.833 118.495 180.651C119.915 180.456 121.23 181.471 121.412 182.891L125.827 216.46C126.009 217.892 125.006 219.195 123.587 219.377C123.47 219.39 123.352 219.403 123.248 219.403Z" fill="white" />
        <path d="M164.201 178.294C163.615 178.294 163.016 178.099 162.534 177.695L151.791 168.723C150.685 167.799 150.541 166.158 151.466 165.051C152.39 163.944 154.031 163.801 155.138 164.726L165.881 173.697C166.988 174.622 167.131 176.263 166.206 177.37C165.685 177.982 164.956 178.307 164.201 178.307V178.294Z" fill="white" />
        <path d="M161.662 69.161C161.102 69.161 160.555 68.9917 160.073 68.6271C158.927 67.7547 158.719 66.114 159.591 64.9811L197.666 15.3562C198.539 14.2103 200.179 14.002 201.312 14.8744C202.458 15.7469 202.666 17.3876 201.794 18.5205L163.719 68.1453C163.211 68.8094 162.43 69.161 161.649 69.161H161.662Z" fill="white" />
        <path d="M185.126 93.1986C184.228 93.1986 183.355 92.7299 182.874 91.8965C182.158 90.6464 182.587 89.0578 183.824 88.3416L201.885 77.9114C203.135 77.1952 204.724 77.6249 205.44 78.862C206.156 80.112 205.726 81.7007 204.489 82.4168L186.429 92.8471C186.025 93.0814 185.569 93.1986 185.126 93.1986Z" fill="white" />
        <path d="M141.4 187.565C140.411 187.565 139.46 186.992 139.017 186.029L133.353 173.411C132.767 172.096 133.353 170.559 134.668 169.973C135.983 169.387 137.52 169.973 138.106 171.288L143.77 183.906C144.356 185.221 143.77 186.758 142.455 187.344C142.103 187.5 141.752 187.578 141.387 187.578L141.4 187.565Z" fill="white" />
        <path d="M236.522 170.559C236.301 170.559 236.079 170.533 235.845 170.468L192.835 158.944C191.442 158.566 190.621 157.147 190.999 155.754C191.377 154.361 192.796 153.54 194.189 153.918L237.199 165.442C238.593 165.819 239.413 167.239 239.035 168.632C238.723 169.791 237.668 170.559 236.522 170.559Z" fill="white" />
        <path d="M132.481 52.3633C132.259 52.3633 132.025 52.3373 131.804 52.2722C130.41 51.8945 129.59 50.4752 129.967 49.0819L137.702 20.2133C138.08 18.82 139.499 17.9996 140.893 18.3772C142.286 18.7548 143.106 20.1742 142.729 21.5675L134.994 50.4361C134.681 51.595 133.627 52.3633 132.481 52.3633Z" fill="white" />
        <path d="M205.219 111.09C204.073 111.09 203.018 110.322 202.706 109.163C202.328 107.77 203.161 106.35 204.542 105.973L231.131 98.85C232.525 98.4724 233.944 99.3057 234.322 100.686C234.699 102.079 233.866 103.499 232.486 103.876L205.896 110.999C205.674 111.064 205.44 111.09 205.219 111.09Z" fill="white" />
        <path d="M134.056 206.512C132.91 206.512 131.856 205.743 131.543 204.584L124.316 177.604C123.938 176.211 124.772 174.791 126.152 174.414C127.545 174.036 128.965 174.869 129.342 176.25L136.569 203.23C136.947 204.623 136.114 206.043 134.733 206.42C134.512 206.486 134.278 206.512 134.056 206.512Z" fill="white" />
        <path d="M184.202 179.531C183.759 179.531 183.317 179.414 182.9 179.18L163.003 167.695C161.753 166.978 161.336 165.377 162.052 164.14C162.769 162.89 164.357 162.46 165.607 163.189L185.504 174.674C186.754 175.39 187.171 176.992 186.455 178.229C185.973 179.062 185.1 179.531 184.202 179.531Z" fill="white" />
        <path d="M160.268 49.3814C159.826 49.3814 159.383 49.2642 158.966 49.0298C157.716 48.3136 157.3 46.712 158.016 45.4749L183.512 1.30603C184.228 0.0559682 185.817 -0.373741 187.067 0.355462C188.317 1.07164 188.733 2.67329 188.017 3.91033L162.521 48.0792C162.039 48.9126 161.167 49.3814 160.268 49.3814Z" fill="white" />
        <path d="M175.439 86.3363C174.657 86.3363 173.889 85.9847 173.368 85.3206C172.496 84.1747 172.704 82.5471 173.85 81.6746L200.596 61.1528C201.742 60.2803 203.37 60.4887 204.242 61.6346C205.114 62.7804 204.906 64.4081 203.76 65.2806L177.014 85.8024C176.545 86.167 175.985 86.3363 175.425 86.3363H175.439Z" fill="white" />
        <path d="M253.541 155.533C253.424 155.533 253.307 155.533 253.203 155.506L204.281 149.061C202.849 148.879 201.846 147.563 202.041 146.144C202.224 144.712 203.552 143.722 204.958 143.904L253.88 150.35C255.312 150.532 256.315 151.847 256.12 153.267C255.95 154.582 254.831 155.533 253.541 155.533Z" fill="white" />
        <path d="M118.704 69.747C118.587 69.747 118.47 69.747 118.365 69.7209C116.933 69.5386 115.93 68.2234 116.126 66.8041L120.735 31.7633C120.918 30.3309 122.246 29.3283 123.652 29.5236C125.084 29.7059 126.087 31.0211 125.892 32.4404L121.282 67.4812C121.113 68.7964 119.993 69.747 118.704 69.747Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1_409">
          <rect width="260" height="232" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default Particles;